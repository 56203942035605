<script lang="ts" setup>
const settingsStore = useSettingsStore();
</script>
<template>
  <UCheckbox
    v-model="settingsStore.preferences.persistMnemonic"
    :help="$t('generics.mnemonic.save-phrase.description')"
    class="my-2"
  >
    <template #label>
      <div class="flex items-center gap-1">
        {{ $t('generics.mnemonic.save-phrase') }}
        <UTooltip :text="$t('generics.mnemonic.save-phrase.tooltip')">
          <UIcon
            name="i-heroicons-information-circle"
            class="size-5"
          />
        </UTooltip>
      </div>
    </template>
  </UCheckbox>
</template>
